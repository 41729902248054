import React from "react"
import Layout from "../components/layout"
import {
  Title,
  Overview,
  Toolset,
  TitleContent,
  Youtube,
} from "../components/parts"

const AugmentedReality = () => (
  <Layout title="Augmented reality">
    <Title heading="AR" sub="Experiements with Augmented Reality" />
    <Overview
      project="augmented-reality"
      text={[
        "Fun experiments to explore the possibilities of Augmented reality.",
      ]}
    />
    <Toolset project="augmented-reality" />
    <TitleContent title="Credit cards">
      <Youtube src="https://www.youtube.com/embed/UFN98xeu9Pk" />
    </TitleContent>
    <TitleContent title="Video postcard">
      <Youtube src="https://www.youtube.com/embed/QRawzp1YCaU" />
    </TitleContent>
    <TitleContent title="World tracking">
      <p>
        Image detection to place an anchor, fetching dynamic data from IKEA ..
      </p>
      <Youtube src="https://www.youtube.com/embed/S4Kd9scazoM" />
      <p>Loading the world, retrieving anchors ..</p>
      <Youtube src="https://www.youtube.com/embed/9EUOMUTtjck" />
    </TitleContent>
    <TitleContent title="Realtime streaming">
      <Youtube src="https://www.youtube.com/embed/ojytVx6qxjE" />
    </TitleContent>
  </Layout>
)

export default AugmentedReality
